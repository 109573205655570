<template>
  <div id="container" v-if="fetch_data">
    <section id="mypage">
      <div class="wrap">

        <div id="register" class="cts_box">
          <template v-if="current_user.sns_rank === 'rank_regular_default'">
            <h2 class="sub_ttl"><span>「会社四季報ONLINE」オプションの追加・変更</span></h2>
            <p class="lead_txt">
              本科生（旧月謝適用中）の方に向けた「会社四季報ONLINE」の優待プラン（月払い）をご用意しております。
            </p>
          </template>
          <template v-else>
            <h2 class="sub_ttl"><span>塾生クラスの変更</span></h2>
            <h3 class="min_ttl">ご希望の変更内容をご確認ください。</h3>
            <div class="class_bf_af flex">
              <div class="inBox flex bf">
                <div class="list flex">
                  <span>現在のクラス</span>
                  <p>{{ current_user.rank_label }}</p>
                </div>
                <div class="list flex">
                  <span>お支払い方法</span>
                  <p>{{ current_user.payment_monthly ? '月額払い' : '年額払い' }}</p>
                </div>
                <div class="list flex">
                  <span>次回の更新月</span>
                  <p>{{ moment(current_user.next_payment_date, 'YYYY年MM月') }}</p>
                </div>
                <div class="list flex">
                  <span>次の支払いまであと</span>
                  <p>{{ diffMonth(new Date(), new Date(current_user.next_payment_date)) }}ヶ月</p>
                </div>
              </div>
              <div class="arrow"></div>
              <div class="inBox flex af">
                <h4>変更を希望するクラス</h4>
                <div class="new_class">本科（旧月謝適用中）</div>
                <p class="red bold">「会社四季報ONLINE」の優待プランをお選びいただけます</p>
              </div>
            </div>
          </template>

          <br />
          <h3 class="min_ttl">ご希望のプランをお選びください</h3>
          <!-- 旧月謝適用中 -->
          <div class="reg_set first" v-if="current_user.sns_rank === 'rank_regular_default'">
            <div class="class_list_box">
              <div class="tax_ttl flex flex_betweem head">
                <p class="c00">&nbsp;</p>
                <p class="c01">今回のお支払い額</p>
                <p class="c02">次回からのお支払い額</p>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_none" name="四季報ONLINEプラン" v-model="rank"
                       value="rank_default" @change="shikihoChanged()">
                <label class="flex" for="shikihou_none">
                  <div class="inner flex">
                    <div class="class shikihou_option min">会社四季報ONLINE の優待プランを選択しない</div>
                    <div class="price pc">&nbsp;</div>
                    <div class="price pc">&nbsp;</div>
                    <div class="info pc">&nbsp;</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_premium" name="四季報ONLINEプラン" v-model="rank"
                       value="rank_regular_default_shikiho_premium_month" @change="shikihoChanged()">
                <label class="flex" for="shikihou_premium">
                  <div class="inner flex">
                    <div class="class">四季報ONLINE プレミアムプランを追加する</div>
                    <div class="price ttl_in"><span class="title sp">今回の<br>お支払い額</span>¥{{
                        delimited(fetch_data.rank_regular_default_shikiho_premium_month.price)
                      }}
                    </div>
                    <div class="price ttl_in"><span class="title sp">お支払い額</span>¥4,167/月</div>
                    <div class="info">¥1,333/月 お得！</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_basic" name="四季報ONLINEプラン" v-model="rank"
                       value="rank_regular_default_shikiho_basic_month" @change="shikihoChanged()">
                <label class="flex" for="shikihou_basic">
                  <div class="inner flex">
                    <div class="class">四季報ONLINE ベーシックプランを追加する</div>
                    <div class="price ttl_in"><span class="title sp">今回の<br>お支払い額</span>¥{{
                        delimited(fetch_data.rank_regular_default_shikiho_basic_month.price)
                      }}
                    </div>
                    <div class="price ttl_in"><span class="title sp">お支払い額</span>¥1,000/月</div>
                    <div class="info">¥100/月 お得！</div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>
          </div>

          <!-- 旧月謝適用中の権利者-->
          <div class="reg_set first" v-else>
            <div class="class_list_box">
              <div class="tax_ttl flex flex_betweem head">
                <p class="c00">&nbsp;</p>
                <p class="c01">今回のお支払い額</p>
                <p class="c02">次回からのお支払い額</p>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_none_2" name="四季報ONLINEプラン" v-model="rank"
                       value="rank_default" @change="shikihoChanged()">
                <label class="flex" for="shikihou_none_2">
                  <div class="inner flex">
                    <div class="class shikihou_option min">会社四季報ONLINE の優待プランを選択しない</div>
                    <div class="price ttl_in"><span class="title sp">今回の<br>お支払い額</span>¥{{
                        delimited(fetch_data.rank_default.price)
                      }}
                    </div>
                    <div class="price ttl_in"><span class="title sp">お支払い額</span>¥10,000/月</div>
                    <div class="info"></div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_premium_2" name="四季報ONLINEプラン" v-model="rank"
                       value="rank_regular_default_shikiho_premium_month" @change="shikihoChanged()">
                <label class="flex" for="shikihou_premium_2">
                  <div class="inner flex">
                    <div class="class">四季報ONLINE プレミアムプランを追加する</div>
                    <div class="price ttl_in"><span class="title sp">今回の<br>お支払い額</span>¥{{
                        delimited(fetch_data.rank_regular_default_shikiho_premium_month.price)
                      }}
                    </div>
                    <div class="price ttl_in"><span class="title sp">お支払い額</span>¥14,167/月</div>
                    <div class="info"></div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="class_list flex check_radio unfinished">
                <input type="radio" id="shikihou_basic_2" name="四季報ONLINEプラン" v-model="rank"
                       value="rank_regular_default_shikiho_basic_month" @change="shikihoChanged()">
                <label class="flex" for="shikihou_basic_2">
                  <div class="inner flex">
                    <div class="class">四季報ONLINE ベーシックプランを追加する</div>
                    <div class="price ttl_in"><span class="title sp">今回の<br>お支払い額</span>¥{{
                        delimited(fetch_data.rank_regular_default_shikiho_basic_month.price)
                      }}
                    </div>
                    <div class="price ttl_in"><span class="title sp">お支払い額</span>¥11,000/月</div>
                    <div class="info"></div>
                  </div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>
          </div>

        </div>

        <div v-if="rank === current_user.rank" style="margin-bottom: 20px;">
          <p>※ 現在契約中のプランです</p>
        </div>

        <div class="input_set email" v-if="showShikihoAccount()">
          <div class="label">東洋経済新報社の「会社四季報ONLINE（有償版）」にすでに加入している方はチェックを入れてください。</div>
          <div class="checkbox">
            <input id="sns_notice02" type="checkbox" v-model="show_shikiho_account"
                   @change="changeShikihoAccount()">
            <label for="sns_notice02" class="flex">
              会社四季報ONLINE（有償版）加入済み
            </label>
          </div>
          <template v-if="show_shikiho_account">
            <p class="mt5">
              <span class="bold">年間契約の残存期間がある方は返金を行います。下記の欄に会社四季報ONLINEのログイン用ID（メールアドレス）を入力してください。</span><br>
              後日、返金のための銀行口座を事務局よりおうかがいします。<br>
              「会社四季報ONLINE（<span class="red bold">ゲスト・無料会員</span>）」の方は<span class="red bold">返金がないため対象外</span>です。<br>
              <span class="red bold">「会社四季報ONLINE」のIDをお持ちでない方は何も入力しないでください。</span>IDの確認方法は<a href="https://www.millioneyes.jp/shikiho_online_id/" target="_blank"><span class="bold">こちら。</span></a>
            </p>
            <div class="label" style="vertical-align: middle">
              <img src="/common/images/shikiho_logo.svg" style="width: 100px; vertical-align: middle" alt="">
              会社四季報ONLINEログイン用ID（メールアドレス）
            </div>
            <input type="tel" v-model="shikiho_account"
                   @input="shikiho_account = replaceFullToHalf(shikiho_account)"
                   placeholder="">
            <div class="shikiho_account_guide">
              （１）すでにご利用いただいている「会社四季報ONLINE」の年間契約が残っている場合は塾生割引と重複する期間分をご返金いたします。<br>
              （２）すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）を塾生割引のログイン用メールアドレス に&nbsp;<span class="red bold">紐付け</span>&nbsp;を行います。（紐付けを行うことにより各種の設定や銘柄等の設定を引き継ぐことができます）<br>
              （３） 紐付けが完了すると、すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）でログインできるようになります。（紐付けが完了するまではすでにご利用いただいている ログイン用メールアドレス 、塾生割引ログイン用メールアドレス ともにお使いいただけます）<br>
            </div>
          </template>
        </div>

        <div v-bind:class="{ 'point-disabled': !fetch_data[rank].price }">
          <div class="cts_box claim workshop" v-if="true">
            <h3 class="min_ttl">ポイントの利用</h3>
            <p class="lead_txt">ポイントを利用する場合は、ポイント数を入力の上「ポイントを利用する」ボタンを押してください。</p>
            <table class=""><!-- ポイント利用できない場合はclass="point_none" を追加 -->
              <tbody>
              <tr class="head">
              </tr>
              <tr>
                <td class="date">現在のポイント数</td>
                <td class="price">{{ delimited(total_points) }}<span>ポイント</span></td>
                <td class="point flex">
                  <h3 class="sp">ポイント利用額（1ポイント＝1円）</h3>
                  <p>ご利用ポイントを入力</p>
                  <div class="point_input">
                    <input type="text" v-model="use_point"
                           @input="use_point = replaceFullToNumber(use_point)"
                           v-bind:disabled="total_points === 0"
                           maxlength="6" class="point">
                    <p class="caution">最大 {{ delimited(Math.min(total_points, fetch_data[rank].price)) }}
                      ポイントまでご利用いただけます</p>
                    <p>1ポイント＝1円として利用できます</p>
                  </div>
                  <p class="bold">ポイント</p>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="btn">
                  <button type="button" class="submit light skeleton"
                          v-bind:disabled="total_points === 0"
                          @click="usePointClicked">ポイントを利用する
                  </button>
                </td>
                <!--<td colspan="3" class="btn"><input type="submit" class="submit light skeleton" value="現在ポイントの利用はできません" disabled></td> ポイント利用できない場合-->
              </tr>
              </tbody>
            </table>
          </div>

          <div class="cts_box">
            <h3 class="min_ttl">お会計内容</h3>
            <div class="register_box">
              <dl class="flex">
                <dt>{{ fetch_data[rank].title }} への変更</dt>
                <dd class="price">¥{{ delimited(fetch_data[rank].price) }}</dd>
              </dl>
              <dl class="flex" v-if="false">
                <dt>クーポンのご利用</dt>
                <dd class="item"></dd>
                <dd class="price">¥-6,500</dd>
              </dl>
              <dl class="flex" v-if="point">
                <dt>ポイントのご利用</dt>
                <dd class="item"></dd>
                <dd class="price">-{{ delimited(point) }}</dd>
              </dl>
              <dl class="flex total">
                <dt></dt>
                <dd class="item">お会計合計：</dd>
                <dd class="price">¥{{ delimited(total_price) }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <br/>

        <div class="btn_wrap">
          <p class="alnC sp_alnL mb10">内容がよろしければこちらのボタンを押してください。</p>
          <a href="javascript:void(0);" class="submit light arrow large sp_h_auto"
             v-bind:class="{disabled: !changeEnabled()}" @click="changeRank()">変更内容の確認</a>
        </div>

        <div class="btn_wrap mt40">
          <p class="alnC sp_alnL mb10">加入を中止する場合はこちらのボタンを押してください。</p>
          <router-link :to="{name: 'UsersCustomerRankChange'}" class="submit light skeleton arrow back large">
            前のページにもどる
          </router-link>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link>
        </li>
        <li>塾生クラスの変更</li>
      </ul>
    </div>
  </div>

</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null,
      rank: 'rank_regular_default_shikiho_premium_month',

      total_points: 0,
      use_point: null,
      point: null,
      total_price: null,
      shikiho_account: null,
      show_shikiho_account: false
    }
  },
  methods: {
    usePointClicked() {
      if (isNaN(this.use_point)) return

      if (this.use_point > this.fetch_data[this.rank].price) {
        this.use_point = this.fetch_data[this.rank].price
      }
      this.point = this.use_point
      this.total_price = this.fetch_data[this.rank].price - this.use_point
    },

    changeEnabled() {
      return this.rank !== null && this.current_user.rank !== this.rank && this.fetch_data[this.rank].level !== 'error'
    },

    shikihoChanged() {
      this.use_point = this.point = null
      this.total_price = this.fetch_data[this.rank].price - this.use_point
      if (!this.showShikihoAccount()) {
        this.shikiho_account = null
      }
    },

    changeRank() {
      if (!this.showShikihoAccount()) {
        this.shikiho_account = null
      }
      if (this.shikiho_account && this.shikiho_account.match('@toyokeizai.net')) {
        this.shikiho_account = this.shikiho_account.split('@')[0]
      }
      // ランク変更確認へ
      this.$store.state.change_rank_info = this.fetch_data[this.rank]
      this.$store.state.change_rank_info.rank = this.rank
      this.$store.state.change_rank_info.use_point = this.point
      this.$store.state.change_rank_info.total_price = this.total_price
      this.$store.state.change_rank_info.shikiho_account = this.shikiho_account

      this.$router.push({name: 'UsersCustomerRankChangeConfirm'})
    },

    changeShikihoAccount() {
      if (!this.show_shikiho_account) {
        this.shikiho_account = null
      }
    },

    showShikihoAccount() {
      // console.log(this.rank, this.isShikiho())
      return !this.isShikiho() && (
          this.rank === 'rank_regular_default_shikiho_premium_month' ||
          this.rank === 'rank_regular_default_shikiho_basic_month');
    }
  },

  mounted() {
    // 旧塾生専用のページ
    if (this.current_user.sns_rank !== 'rank_regular_default' && !this.current_user.old_rank_default) {
      this.$router.push({name: 'UsersCustomerRankChange'})
    }

    // ランク変更先の価格リストを取得
    this.startAjax()
    this.axios
        .post(`${this.env.api_base_url}users/new_rank_info.json`, {
          new_rank: 'default'
        })
        .then((response) => {
          // console.log(response.data)
          this.fetch_data = response.data
          this.total_points = response.data.total_points
          this.shikihoChanged()
          if (this.$store.state.change_rank_info) {
            this.rank = this.$store.state.change_rank_info.rank
            this.use_point = this.point = this.$store.state.change_rank_info.use_point
            this.total_price = this.fetch_data[this.rank].price - this.use_point
          }
        })
        .catch((response) => {
          this.errorAjax(response)
        })
        .finally(() => {
          this.finishAjax()
        })
  }
}
</script>

<style>
.point-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>